<template>
  <div class="px-10">
    <!---->
    <div class="text-h4 mb-5 primary--text">
      <!--ขนาดข้อความหนา h4 ....5 สีข้อความprimary -->
      {{ isUpdate ? 'แก้ไข' : 'สร้าง' }}ข้อมูลลูกค้า
      <!--ปุ่ม?ข้อความ?เพื่อรับการอัปเดท-->
    </div>

    <v-row justify="center">
      <v-col cols="5" class="text-center">
        <v-row>
          <!-- <v-col>
            <v-avatar color="primary" size="200">
              <img
                src="http://via.placeholder.com/200x200?text=Image"
                width="200"
                height="200"
              />
            </v-avatar>
            <div class="mt-5 grey--text">รูปสำเนาบัตรประชาชน</div>
          </v-col> -->
          <v-col>
            <!--แบ่งหน้าแนวตั้ง-->
            <!--ช่องทำเครื่องหมาย ป้ายซ่อนรายละเอียด -->
            <v-checkbox
              dense
              hide-details
              label="โทรแจ้งเมื่องานเสร็จ"
              v-model="form.call_alert"
            />
            <v-checkbox
              dense
              hide-details
              label="โทรเตือนต่ออายุ"
              v-model="form.call_remind_renewal"
            />
            <!-- <v-checkbox
              dense
              hide-details
              label="ส่ง SMS แจ้งต่ออายุ"
              v-model="form.sms_alert"
            />
            <v-checkbox
              dense
              hide-details
              label="ส่งจดหมายแจ้งต่ออายุ"
              v-model="form.letter_alert"
            /> -->
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5"> </v-col>
    </v-row>

    <v-row justify="center">
      <!--แบ่งหน้าแถวแนวนอน-->
      <v-col cols="5">
        <!--<div class=pl-10">-->
        <v-text-field
          name="name"
          label="ชื่อ"
          outlined
          dense
          v-model="form.name"
          :error-messages="$v.form.name.$error ? $error_text : ''"
        />
        <v-text-field
          name="phone_number"
          id="phone_number"
          v-model="form.phone_number"
          label="เบอร์โทร"
          :class="{'as-password': !toggleShowPhoneNumber}"
          :error-messages="$v.form.phone_number.$error ? $error_text : ''"
          autocomplete="new-password"
          outlined
          dense
          @keypress.native="isNumber1"
          @keydown="preventDelete1"
          @input="parsePhonePattern1"
        />
        <v-text-field
          name=""
          label="ที่อยู่"
          outlined
          dense
          autocomplete="new-password"
          @click="
            (dialogAddressData = form.location),
              (dialogAddress = true),
              (dialogAddressKey = 'location')
          "
          :value="`${form.location.address} ${form.location.sub_district} ${form.location.district} ${form.location.province} ${form.location.postal_code}`"
        />
        <v-select
          name="customer_group"
          v-model="form.customer_group"
          label="กลุ่มลูกค้า/ตัวแทน"
          item-value="_id"
          item-text="name"
          outlined
          dense
          :items="list.discounted_group"
        ></v-select>
        <v-text-field
          name="phone_number2"
          id="phone_number2"
          v-model="form.phone_number2"
          label="เบอร์โทร (2)"
          :class="{'as-password': !toggleShowPhoneNumber2}"
          autocomplete="new-password"
          :error-messages="$v.form.phone_number2.$error ? $error_text : ''"
          outlined
          dense
          @keypress.native="isNumber2"
          @keydown="preventDelete2"
          @input="parsePhonePattern2"
        />
        <v-text-field
          name="delivery_address"
          label="ที่อยู่(จัดส่งเอกสาร)"
          outlined
          dense
          autocomplete="new-password"
          @click="
            (dialogAddressData = form.delivery_address),
              (dialogAddress = true),
              (dialogAddressKey = 'delivery_address')
          "
          :value="`${form.delivery_address.address} ${form.delivery_address.sub_district} ${form.delivery_address.district} ${form.delivery_address.province} ${form.delivery_address.postal_code}`"
        />
        <!-- </div> -->
      </v-col>
      <v-col cols="5" offset="1">
        <v-text-field
          name="taxpayer_number"
          v-model="form.taxpayer_number"
          label="เลขประจำตัวผู้เสียภาษี (กรณีนิติบุคคล หรือ บริษัท)"
          outlined
          dense
          @keypress.native="taxNumber"
        />
        <v-text-field
          name="company_branch"
          v-model="form.company_branch"
          label="สาขา (กรณีนิติบุคคล หรือ บริษัท)"
          outlined
          dense
        />
        <DatePicker
          label="วันเดือนปีเกิด"
          :value="form.birthday"
          @onChange="(val) => (form.birthday = val)"
          :max="maxDate"
          :min="minDate"
        />
        <v-radio-group class="mt-1 mb-3" row v-model="form.gender">
          <div class="mr-5">เพศ :</div>
          <v-radio name="" label="ไม่ระบุ" :value="0"></v-radio>
          <v-radio name="" label="หญิง" :value="1"></v-radio>
          <!-- <v-spacer/> -->
          <v-radio name="" label="ชาย" :value="2"></v-radio>
        </v-radio-group>
        <v-textarea
          name="note"
          v-model="form.note"
          label="หมายเหตุ"
          outlined
          dense
        />
      </v-col>
    </v-row>

    <div class="text-h5 grey--text mb-5">รถในครอบครอง</div>

    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="form.car_ownership"
      :loading="loadingCar"
      sort-by="id"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      hide-default-footer
      :loading-text="$table_loading_text"
      @page-count="pageCount = $event"
    >
      <template v-slot:[`item.index`]="{ item }">
        {{
          form.car_ownership
            .map(function (x) {
              return x._id;
            })
            .indexOf(item._id) + 1
        }}
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        {{
          list.car_type_dict.filter((el) => {
            return el.value == item.car_type;
          })[0].name
        }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <v-row>
      <v-col class="text-right py-10">
        <v-btn class="px-10 mr-15" color="grey" outlined @click="close()">
          ปิด
        </v-btn>
        <v-btn
          class="px-10"
          color="primary"
          @click="save()"
          :loading="loading"
          :disabled="loading"
        >
        บันทึกข้อมูล
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </div>
</template>

<script>
/**********************************************************/
import AddressDetail from '@/components/Address/AddressDetail.vue';
import DatePicker from '@/components/DatePicker.vue';
import {
  required,
  maxLength,
  minLength,
  numeric,
  minValue,
  alpha,
} from 'vuelidate/lib/validators';
import * as moment from 'moment';

export default {
  components: {
    AddressDetail,
    DatePicker,
  },
  validations: {
    form: {
      name: { required },
      phone_number: { minLength: minLength(9), maxLength: maxLength(11) },
      phone_number2: { minLength: minLength(9), maxLength: maxLength(11) },
      taxpayer_number: {
        numeric,
        minLength: minLength(13),
        maxLength: maxLength(13),
      },
    },
  },
  data: () => ({
    selectStart1: -1,
    selectStart2: -1,
    form: {
      branch_id: '',
      customer_id: 0,
      name: '',
      phone_number: '',
      phone_number2: '',
      birthday: moment().add(-1, 'days').format('YYYY-MM-DD'),
      gender: 0,
      location: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: '',
      },
      taxpayer_number: '',
      delivery_address: {
        address: '',
        district: '',
        sub_district: '',
        province: '',
        postal_code: '',
      },
      customer_group: '',
      identification_card_url: '',
      car_ownership: [],
      sms_alert: false,
      call_alert: false,
      letter_alert: false,
      call_remind_renewal: false,
      note: '',
    },

    loading: true,
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      {
        text: 'ลำดับ',
        align: 'center',
        sortable: false,
        value: 'index',
        filterable: false,
      },
      { text: 'ทะเบียน', value: 'car_plate' },
      { text: 'จังหวัด', value: 'province' },
      { text: 'ประเภทรถ', value: 'car_type' },
      { text: '', value: 'actions', sortable: false },
    ],

    dialogUpdate: false,
    dialogData: null,
    loadingCar: true,
    dialogAddressKey: '',
    dialogAddress: false,
    dialogAddressData: {
      address: '',
      district: '',
      postal_code: '',
      province: '',
      sub_district: '',
    },
    showPhoneNumber: false,
    maxDate: moment().add(-1, 'days').format('YYYY-MM-DD'),
    minDate: moment().add(-100, 'years').format('YYYY-MM-DD'),
    modal: false,
    list: {
      discounted_group: [],
      car_type_dict: [
        { value: 'CT1', name: '(รย.1) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT2', name: '(รย.2) รถยนต์นั่งส่วนบุคคลเกิน 7 คน' },
        { value: 'CT3', name: '(รย.3) รถยนต์บรรทุกส่วนบุคคล' },
        { value: 'CT4', name: '(รย.12) รถจักรยานยนต์ส่วนบุคคล' },
        { value: 'CT13', name: '(รย.17) รถจักรยานยนต์สาธารณะ' },
        { value: 'CT5', name: '(รย.13) รถแทรกเตอร์ที่ใช้ในการเกษตร' },
        { value: 'CT14', name: '(รย.15) รถใช้งานเกษตรกรรม' },
        {
          value: 'CT6',
          name: '(ขส.10) รถโดยสารประจำทาง (เลขทะเบียนข้างหน้าเป็น 10-19)',
        },
        {
          value: 'CT7',
          name: '(ขส.30) รถโดยสารไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 30-39)',
        },
        {
          value: 'CT8',
          name: '(ขส.40) รถโดยสารส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 40-49)',
        },
        {
          value: 'CT9',
          name: '(ขส.70) รถบรรทุกไม่ประจำทาง (เลขทะเบียนข้างหน้าเป็น 70-79)',
        },
        {
          value: 'CT10',
          name: '(ขส.80) รถบรรทุกส่วนบุคคล (เลขทะเบียนข้างหน้าเป็น 50-59, 80-99)',
        },
        { value: 'CT11', name: '(รย.1EV) รถยนต์นั่งส่วนบุคคลไม่เกิน 7 คน' },
        { value: 'CT12', name: 'อื่นๆ' },
      ],
    },
    showPhoneNumber: false,
    transTimeout: null,
    transTimeout2: null,
    toggleShowPhoneNumber: false,
    toggleShowPhoneNumber2: false,
    editMode: null,
    deleteOnce: false,
    deleteOnce2: false,
  }),
  watch: {
    'form.phone_number': function (val){
      if(this.showPhoneNumber){
        return
      }
      if (this.transTimeout) {
        clearTimeout(this.transTimeout);
        this.transTimeout = null;
      }
      if(val.length == 0 ){
        this.toggleShowPhoneNumber = true
      } else if(val.length >= 10){
        this.transTimeout = setTimeout( () =>{
          this.toggleShowPhoneNumber = false
        }, 5000);
      }
    },
    'form.phone_number2': function (val){
      if(this.showPhoneNumber){
        return
      }
      if (this.transTimeout2) {
        clearTimeout(this.transTimeout2);
        this.transTimeout2 = null;
      }
      if(val.length == 0 ){
        this.toggleShowPhoneNumber2 = true
      } else if(val.length >= 10){
        this.transTimeout2 = setTimeout( () =>{
          this.toggleShowPhoneNumber2 = false
        }, 5000);
      }
    },
    isEditFilterExpressProduct: function (newVal, oldVal) {
      if (newVal && !this.loading) {
        this.getExpressProduct();
      }
    },
  },

  async created() {
    if (this.isUpdate) await this.getData();
    this.editMode = this.$route.params.id ? true : false;
    this.form.branch_id = this.$store.state.selected_branch._id;
    await this.getListDiscountedGroups();
    await this.getShowPhoneNumber();
    if(!this.editMode){
      this.toggleShowPhoneNumber = true
      this.toggleShowPhoneNumber2 = true
    }
    this.loading = false;
    this.loadingCar = false;
  },

  methods: {
    onChangeDate(val) {
      this.form.birthday = moment(val).format('YYYY-MM-DD');
    },
    isNumber1(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.phone_number.length >= 11 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    preventDelete1(evt) {
      let arrowKey = [37, 38, 39, 40];
      let selectStart = evt.target.selectionStart;
      let selectEnd = evt.target.selectionEnd;
      if(this.editMode && !this.showPhoneNumber && !this.deleteOnce){
        this.deleteOnce = true
        this.form.phone_number = ""
        return
      }
      if (evt.keyCode === 8 || evt.keyCode === 46) {
        if (evt.target.value[selectStart - 1] == '-') {
          setTimeout(
            function () {
              this.form.phone_number = this.parsePhonePattern1(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
              evt.target.value = this.parsePhonePattern1(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
            }.bind(this),
            0
          );
        }
      } else {
        if (evt.target.value.length >= 12 && !arrowKey.includes(evt.keyCode)) {
          evt.preventDefault();
        } else {
          this.selectStart1 = -1;
        }
      }
    },
    parsePhonePattern1(value, returnValue = false) {
      let position = value
        .substr(0, 12)
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,7})/);
      let phoneNumber = '';
      phoneNumber = position[1];

      if (position[1].length >= 3) {
        phoneNumber += '-';
      }

      phoneNumber += position[2];

      if (returnValue) {
        return phoneNumber;
      } else {
        this.form.phone_number = phoneNumber;
        if (this.selectStart1 > -1) {
          let input = document.getElementById('phone_number');
          let selectionStart = this.selectStart;
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart);
          }, 0);
        } else if (window.event.target.selectionStart < value.length) {
          let input = document.getElementById('phone_number');
          let selectionStart = window.event.target.selectionStart;
          console.log(selectionStart);
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart, 'forward');
          }, 0);
        }
      }
    },
    async getShowPhoneNumber() {
     try {
        if (Object.keys(this.$store.state.group_permissions).length === 0) {
            await this.$store.dispatch('getGroupPermissions');
        };

        this.showPhoneNumber = this.$store.state.group_permissions.show_phone_number;
        if (this.showPhoneNumber == 0) {
          this.showPhoneNumber = false
          this.toggleShowPhoneNumber = false      
          this.toggleShowPhoneNumber2 = false
        } else {
          this.showPhoneNumber = true;
          this.toggleShowPhoneNumber = true      
          this.toggleShowPhoneNumber2 = true
        };
      } catch (error) {
          console.error('Error in getShowPhoneNumber:', error);
          this.showPhoneNumber = true;
          this.toggleShowPhoneNumber = true      
          this.toggleShowPhoneNumber2 = true
        }
    }, 
    preventDelete2(evt) {
      let arrowKey = [37, 38, 39, 40];
      let selectStart = evt.target.selectionStart;
      let selectEnd = evt.target.selectionEnd;
      if(this.editMode && !this.showPhoneNumber && !this.deleteOnce2){
        this.deleteOnce2 = true
        this.form.phone_number2 = ""
        return
      }
      if (evt.keyCode === 8 || evt.keyCode === 46) {
        if (evt.target.value[selectStart - 1] == '-') {
          setTimeout(
            function () {
              this.form.phone_number2 = this.parsePhonePattern2(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
              evt.target.value = this.parsePhonePattern2(
                evt.target.value.slice(0, selectStart - 2) +
                  evt.target.value.slice(
                    selectStart - 1,
                    evt.target.value.length
                  ),
                true
              );
            }.bind(this),
            0
          );
        }
      } else {
        if (evt.target.value.length >= 12 && !arrowKey.includes(evt.keyCode)) {
          evt.preventDefault();
        } else {
          this.selectStart2 = -1;
        }
      }
    },
    parsePhonePattern2(value, returnValue = false) {
      let position = value
        .substr(0, 12)
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,7})/);
      let phoneNumber = '';
      phoneNumber = position[1];

      if (position[1].length >= 3) {
        phoneNumber += '-';
      }

      phoneNumber += position[2];

      if (returnValue) {
        return phoneNumber;
      } else {
        this.form.phone_number2 = phoneNumber;
        if (this.selectStart2 > -1) {
          let input = document.getElementById('phone_number2');
          let selectionStart = this.selectStart;
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart);
          }, 0);
        } else if (window.event.target.selectionStart < value.length) {
          let input = document.getElementById('phone_number2');
          let selectionStart = window.event.target.selectionStart;
          window.setTimeout(function () {
            input.focus();
            input.setSelectionRange(selectionStart, selectionStart, 'forward');
          }, 0);
        }
      }
    },
    isNumber2(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.phone_number2.length >= 11 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    taxNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else if (
        this.form.taxpayer_number.length >= 13 &&
        evt.target.selectionEnd - evt.target.selectionStart == 0
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getData() {
      /*รับวัน*/
      if (this.$route.params.id) {
        /*เงื่อนไข ฟังก์ชันพารามิเตอร์รับเลข*/
        let body = {
          /*ให้โครงสร้าง โทเคน jwtนี้ลงชื่อ (........) คีย์ลับ ไม่จำกัดเวลา*/
          token: this.$jwt.sign(
            { customer_id: this.$route.params.id },
            this.$privateKey,
            { noTimestamp: true }
          ),
        };
        await this.$axios
          .post(`${this.$baseUrl}/customer/get_by_id`, body)
          .then((res) => {
            this.form = res.result; /*ผลลัพท์*/
            console.log(this.form);
            this.form.phone_number = this.parsePhonePattern1(
              res.result.phone_number,
              true
            );
            this.form.phone_number2 = this.parsePhonePattern2(
              res.result.phone_number2,
              true
            );
            this.itemsPerPage = this.form.car_ownership.length;
          })
          .catch((err) => {
            /*จับerror*/
            this.$router.push({ name: 'customer' });
          });
      }
      if(this.form.phone_number == ''){
        this.toggleShowPhoneNumber = true
      }
      if(this.form.phone_number2 == ''){
        this.toggleShowPhoneNumber2 = true
      }
    },
    async save() {
      /*asynchronous*/ /*บันทึก*/
      this.$v.form.$touch(); /*ตรวจสอบทุกฟิลด์ vue */
      if (this.$v.form.$invalid)
        return; /*invalid=ไม่ถูกต้อง,การเป็นจริงเมื่อการตรวจสอบกลับเป็นเท็จ */

      this.loading = true; /*ถูกต้อง*/
      this.$alertConfirm({ title: `ยืนยันบันทึกข้อมูล ?`, text: `` }).then(
        /*$alertConfirm=แจ้งเตือนยืนยัน*/
        async (result) => {
          /*ผลลัพท์*/
          if (result.isConfirmed) {
            /*เงื่อนไข ผลลัพท์ได้รับการยืนยัน*/
            // delete this.form.car_ownership                              /*ลบจาก ในเจ้าของรถ*/
            let subPath = 'add_customer'; /*ให้ เส้นทาง 'add_customer'*/
            if (this.isUpdate) {
              /*เงื่อนไข กำลังอัพเดท*/
              subPath = 'edit_customer'; /*ให้ เส้นทาง ''edit_customer*/
              this.form = { customer_id: this.form._id, ...this.form }; /**/
              delete this.form._id; /*ลบจากcustomer_id*/
            }
            let sendForm = {
              ...this.form,
              phone_number: this.form.phone_number.replaceAll('-', ''),
              phone_number2: this.form.phone_number2.replaceAll('-', ''),
            };
            let body = {
              token: this.$jwt.sign(sendForm, this.$privateKey, {
                noTimestamp: true,
              }),
            };
            await this.$axios
              .post(`${this.$baseUrl}/customer/${subPath}`, body)
              .then((res) => {
                this.$alertSuccess({ title: res.status });
                if (!this.isUpdate) this.$router.push({ name: 'customer' });
              })
              .catch((err) => {
                console.log('err', err); /*เป็นการผิดแบบไม่โชว์ให้ผู้ใช้ทราบ*/
                this.$alertServerError({
                  title: err.error_message,
                }); /*แจ้งเตือนข้อผิดพลาดของเซิร์ฟเวอร์ */
              });
            console.log('save()');
          }
          this.loading = false;
        }
      );
    },
    getListDiscountedGroups() {
      let body = {
        token: this.$jwt.sign({}, this.$privateKey, { noTimestamp: true }),
      };
      this.$axios
        .post(`${this.$baseUrl}/discounted_groups/list_group`, body)
        .then(async (res) => {
          this.list.discounted_group = res.result;
        });
    },
    close() {
      /*ปิด*/
      this.$router.push({ name: 'customer' });
    },
    edit(item) {
      /*แก้ไข ฟังก์ชันชื่อ...และฟังก์ชันพารามิเตอร์ไอดี...*/
      this.$router.push({ name: 'car-update', params: { id: item._id } });
    },
    remove(item) {
      /*ลบออก*/
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        /*แจ้งเตือนหัวข้อลบ ค่าว่าง */
        async (result) => {
          /*ผลลัพท์*/
          if (result.isConfirmed) {
            /*เงื่อนไข ผลลัพท์ที่ได้การยืนยัน*/
            let body = {
              /*ให้โครงสร้าง ของโทเคน jwtนี้ ลงชื่อ ..... นี่เป็นคีย์ส่วนตัว ไม่จับเวลา */
              token: this.$jwt.sign(
                { car_id: item._id, customer_id: this.form._id },
                this.$privateKey,
                { noTimestamp: true }
              ),
            };
            await this.$axios
              .post(`${this.$baseUrl}/car/delete_car`, body)
              /*แอ็กซี่โอสหลัก? (นี้เป็นพื้นฐานurl ............ ,โครงสร้าง)*/
              .then((res) => {
                /*แล้วแจ้งเตือนความสำเร็จ แล้วรับข้อมูล*/
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch((err) => {
                /*จับerror แจ้งเตือนข้อผิดพลาดของเซิร์ฟเวอร์ คอนโทรนทำการลบออก*/
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    onSubmitAddress(val) {
      /*ในการส่งที่อยู่ วาล? */
      this.form[this.dialogAddressKey] = val; /*นี้ในคีย์ที่อยู่โต้ตอบ=วาล??*/
      this.dialogAddress = false; /*พลาดที่อยู่โต้ตอบ*/
    },
  },
  computed: {
    /*คือการอัพเดทไอดี*/
    isUpdate() {
      return this.$route.params.id;
    },
  },
};
</script>
